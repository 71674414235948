import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../../constants/query-keys";
import { refreshAccessToken } from "../../../api/token";
import { selectUser } from "../../../store/user/selectors";

export const useRefreshAccessToken = (select) => {
  const user = selectUser();

  return useQuery({
    queryKey: [QueryKeys.SESSION_TOKEN],
    queryFn: () => refreshAccessToken({ userID: user?.userID }),
    retry: 0,
    select,
    refetchInterval: 30000, // refetch every 30 seconds
  });
};