import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const name = "[PFW_USER]";

export const userStore = create(
  persist(
    immer(
      devtools(() => ({ user: null }), { name })
    ),
    {
      name,
      storage: {
        getItem: (key) => JSON.parse(sessionStorage.getItem(key)),
        setItem: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
        removeItem: (key) => sessionStorage.removeItem(key),
      },
    }
  )
);