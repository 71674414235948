import { userStore } from "./slice";

export const setUser = (payload) => {
  userStore.setState((state) => {
    state.user = payload;
  });
};

export const logout = () => {
  userStore.setState((state) => {
    state.user = null;
  });
};