import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { bindActionCreators } from "redux";
import { loadStripe } from "@stripe/stripe-js";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../State";

import SiteHeader from "../components/SiteHeader";
import Sitefooter from "../components/footer";
import StripeForm from "../components/StripeForm";
import secureLocalStorage from "react-secure-storage";

import { getUser, getSiteDraws } from "../api/api";
import useDocumentTitle from "../components/useDocumentTitle";
import TopIndicator2 from "../images/donation/indicator-2.svg";
import card from "../images/donation/card.svg";
import lock from "../images/donation/lock.svg";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  SetSubscriptionAmount,
  SetSubscriptionPeriod,
} from "../State/actionCreaters";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { addToCart, initiateCheckout } from "../utils/meta/metaPixelEvent";
import { selectUser } from "../store/user/selectors";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function MySubscriptionRegister() {
  useDocumentTitle("My Subscription Register | Checkout");
  const userDetail = secureLocalStorage.getItem("LogObject");
  console.log("userDetail->", userDetail);

  const userData = userDetail;
  const [isActive, setActive] = useState(false);
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [parkrunId, setParkrunId] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostcode] = useState("");
  const [ConfirmGift, setConfirmGift] = useState(false);
  const [cb1, setcb1] = useState(false);
  const [cb2, setcb2] = useState(false);
  const [cb3, setcb3] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [Termsvalidator, setvalidator] = useState(true);
  const navigate = useNavigate();
  const [isLogin, setiIsLogin] = useState(false);
  const [loginData, setLoginData] = useState(false);
  const [ValidatePhone, setValidatePhone] = useState(false);
  const [ValidateEmail, setValidateEmail] = useState(false);
  const [DrawData, setDrawData] = useState([]);

  const dispatch = useDispatch();
  const { sub_amount, sub_period, sub_mode, sub_o_amount, sub_o_sub_item_id } =
    useSelector((state) => state.subscription);
  const {
    SetSubscriptionAmount,
    SetSubscriptionPeriod,
    SetSubscriptionMode,
    SetSubscriptionOAmount,
    SetSubscriptionOSubItemID,
  } = bindActionCreators(actionCreaters, dispatch);

  useEffect(() => {
    if (userDetail) {
      getUser(userDetail.userID).then((e) => {
        console.log("e", e.data?.content[0]);
        setiIsLogin(true);
        setEmail(e.data?.content[0]?.userEmail);
        setFname(e.data?.content[0]?.userFirstName);
        setLname(e.data?.content[0]?.userSurname);
        setPostcode(e.data?.content[0]?.userAddressPostcode);
        setAddress1(e.data?.content[0]?.userAddressLine1);
        setUserPhoneNumber(e.data?.content[0]?.userPhoneNumber);
        setAddress2(e.data?.content[0]?.userAddressLine2);
        setLoginData(e.data?.content[0]);
      });
    }
  }, []);

  useEffect(() => {
    getSiteDraws().then((res) => {
      setDrawData(res.data?.content[0]);
      console.log("Draw data", res.data?.content[0]);
    });
  }, []);

  if (sub_mode == "") {
    const LSmode = localStorage.getItem("sub_mode");
    const LSamount = localStorage.getItem("sub_amount");
    const LSperiod = localStorage.getItem("sub_period");
    const LSoamount = localStorage.getItem("sub_o_amount");
    const LSosubitemid = localStorage.getItem("sub_o_sub_item_id");
    if (LSamount && LSperiod && LSmode) {
      SetSubscriptionMode(LSmode);
      SetSubscriptionAmount(LSamount);
      SetSubscriptionPeriod(LSperiod);
      SetSubscriptionOAmount(LSoamount);
      SetSubscriptionOSubItemID(LSosubitemid);
    } else {
      alert("please select Amount and period");
      window.location.href = "/account/my-subscription-plan";
    }
  } else {
    localStorage.setItem("sub_mode", sub_mode);
    localStorage.setItem("sub_amount", sub_amount);
    localStorage.setItem("sub_period", sub_period);
    localStorage.setItem("sub_o_amount", sub_o_amount);
    localStorage.setItem("sub_o_sub_item_id", sub_o_sub_item_id);
  }

  // let d_o_period = "Monthly";
  // let sub_o_amount = 0;
  // let sub_o_sub_item_id = ""
  // if (!sub_mode) {
  //     sub_mode = localStorage.getItem('AmountvarDMode');
  //     if (!sub_mode) {
  //         sub_mode = "new";
  //         localStorage.setItem('AmountvarDMode', sub_mode);
  //     }
  // } else {
  //     localStorage.setItem('AmountvarDMode', sub_mode);
  // }

  // if (sub_mode == "edit") {
  //     const LSamount = localStorage.getItem('AmountvarDOAmount');
  //     const LSperiod = localStorage.getItem('AmountvarDOPeriod');
  //     const LSsubid = localStorage.getItem('AmountvarDOSubID');
  //     sub_o_amount = LSamount;
  //     d_o_period = LSperiod;
  //     sub_o_sub_item_id = LSsubid;
  // }
  useEffect(() => {
    setValidatePhone(false);
  }, [userPhoneNumber]);

  function SetElement(param, paramsval) {
    if (param == "email") {
      setEmail(paramsval);
      localStorage.setItem("email", paramsval);
    } else if (param == "fname") {
      setFname(paramsval);
      localStorage.setItem("fname", paramsval);
    } else if (param == "lname") {
      setLname(paramsval);
      localStorage.setItem("lname", paramsval);
    } else if (param == "parkrunId") {
      setParkrunId(paramsval);
    } else if (param == "password") {
      setPassword(paramsval);
    } else if (param == "Cpassword") {
      setconfirmPassword(paramsval);
    } else if (param == "address1") {
      setAddress1(paramsval);
    } else if (param == "address2") {
      setAddress2(paramsval);
    } else if (param == "town") {
      setTown(paramsval);
    } else if (param == "postcode") {
      setPostcode(paramsval);
    } else if (param == "ConfirmGift") {
      setConfirmGift(paramsval);
    }
  }

  function addressHandler(event) {
    setActive(event.target.checked);
  }

  async function validateHandle() {
    setvalidator(false);
    // toast.error(`You must Fill all the Important Fields before submitting. `, { position: "bottom-left" })

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      toast.error(`Please Enter valid Email`, { position: "bottom-left" });
      return;
    } else if (userPhoneNumber.length < 2) {
      toast.error(`Invalid Phone Number`, { position: "bottom-left" });
      return;
    } else if (!cb3) {
      toast.error(`You must agree terms and conditions before submitting.`, {
        position: "bottom-left",
      });
      return;
    } else {
      // const POSTCODER_KEY = process.env.POSTCODER_API_KEY || 'PCWW5-FVLAY-K6FGB-KSTYM'
      // await toast.promise(axios.get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${email}`).then(async (res) => {
      //     console.log("res.data", res.data)
      //     if (res.data.valid) {
      //         setValidateEmail(true)
      //         await axios.get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/mobile/${userPhoneNumber}`)
      //             .then((res) => {
      //                 console.log("res.data", res.data)
      //                 if (res.data.valid) {
      //                     setValidatePhone(true);
      //                     return
      //                 } else {
      //                     toast.error(`Your Phone Number is not valid, Please Enter a valid Phone Number`, { position: "bottom-left" });
      //                     return
      //                 }
      //             })
      //     } else {
      //         toast.error(`Your email is not valid, Please Enter a valid Email`, { position: "bottom-left" });
      //         return
      //     }
      // }), {
      //     pending: 'Please wait...',
      // }, { position: "bottom-left" }
      // );
      setValidateEmail(true);
      setValidatePhone(true);
    }
  }

  // toast.error(`You must Fill all the Important Fields before submitting. `, { position: "bottom-left" })

  // Data Layer Push for Add to Cart event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      user_id: userDetail?.userID ? userDetail?.userID : "Guest User",
      user_email: userDetail?.email ? userDetail?.email : "Guest User",
      items: [
        {
          item_name: DrawData.drawName,
          item_id: DrawData.drawID,
          price: sub_amount,
          item_brand: "Charity",
          item_category:
            DrawData.drawCategoryID === 1
              ? "Fixed Cash Prize"
              : DrawData.drawCategoryID === 2
              ? "Split Cash Prize"
              : DrawData.drawCategoryID === 3
              ? "Experiential"
              : DrawData.drawCategoryID === 4
              ? "Hybrid"
              : "",
          item_variant: sub_period,
          quantity: 1,
        },
      ],
    },
  });

  // Data Layer Push for Begin Checkout event
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      user_id: userDetail?.userID ? userDetail?.userID : "Guest User",
      user_email: userDetail?.email ? userDetail?.email : "Guest User",
      items: [
        {
          item_name: DrawData.drawName,
          item_id: DrawData.drawID,
          price: sub_amount,
          item_brand: "Charity",
          item_category:
            DrawData.drawCategoryID === 1
              ? "Fixed Cash Prize"
              : DrawData.drawCategoryID === 2
              ? "Split Cash Prize"
              : DrawData.drawCategoryID === 3
              ? "Experiential"
              : DrawData.drawCategoryID === 4
              ? "Hybrid"
              : "",
          item_variant: sub_period,
          quantity: 1,
        },
      ],
    },
  });

  const currency = "GBP";
  const value = sub_amount;
  addToCart(currency, value);
  initiateCheckout(currency, value);

  return (
    <>
      <ToastContainer />
      <SiteHeader />
      <section
        className=" herosec-img-support font-face-sh"
        style={{ marginTop: "-160px", padding: "170px 0 40px" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-5 text-center mx-auto">
              <img className="w-100" src={TopIndicator2} alt="top-Indicator" />
            </div>
            <div className="row text-center align-items-center justify-content-center mx-0 my-4 px-0">
              <div className="col-md-5 col-sm-12 bg-light rounded-3 p-5 text-center">
                <h2 className="font-semibold fs-4 p-2">Checkout </h2>
                <hr />
                <p className="font-light">
                  You'll need to quickly create a parkrun Forever account, this
                  is separate from your parkun profile
                </p>
                {/* ..... */}
                <div className="py-3 text-start">
                  <form action="">
                    <p className="font-semibold fs-5">Personal details</p>
                    <input
                      type="text"
                      style={
                        Termsvalidator || Fname.length > 2
                          ? {}
                          : { borderColor: "red" }
                      }
                      className="px-3 py-2 my-2 d-block w-100 form-control"
                      value={Fname}
                      onChange={(event) =>
                        SetElement("fname", event.target.value)
                      }
                      placeholder="First Name"
                    />
                    <input
                      type="text"
                      className="px-3 py-2 my-2 d-block w-100 form-control"
                      value={Lname}
                      onChange={(event) =>
                        SetElement("lname", event.target.value)
                      }
                      placeholder="Last Name"
                    />
                    <PhoneInput
                      placeholder="+44 0000000000"
                      defaultCountry="GB"
                      countryCallingCodeEditable={false}
                      international
                      value={`${userPhoneNumber}`}
                      name="userPhoneNumber"
                      className="px-3 py-2 my-2 d-flex w-100 form-control"
                      onChange={(e) =>
                        setUserPhoneNumber(`${e?.length ? e : ""}`)
                      }
                    />

                    <p className="font-semibold fs-5 pt-3">Account details</p>
                    <input
                      type="text"
                      onChange={(event) => {
                        SetElement("parkrunId", event.target.value);
                      }}
                      className="px-3 py-2 my-2 d-block w-100 form-control"
                      placeholder="parkrun ID (optional)"
                    />
                    <input
                      type="email"
                      value={email}
                      style={
                        Termsvalidator || email.length > 2
                          ? {}
                          : { borderColor: "red" }
                      }
                      onChange={(event) => {
                        SetElement("email", event.target.value);
                      }}
                      className="px-3 py-2 my-2 d-block w-100 form-control"
                      placeholder="Email"
                      required=""
                    />
                    {isLogin ? (
                      ""
                    ) : (
                      <>
                        <input
                          type="password"
                          style={
                            Termsvalidator || password.length > 2
                              ? {}
                              : { borderColor: "red" }
                          }
                          onChange={(event) => {
                            SetElement("password", event.target.value);
                          }}
                          className="px-3 py-2 my-2 d-block w-100 form-control"
                          placeholder="Choose password"
                        />
                        <input
                          type="password"
                          style={
                            Termsvalidator || confirmPassword.length > 2
                              ? {}
                              : { borderColor: "red" }
                          }
                          onChange={(event) => {
                            SetElement("Cpassword", event.target.value);
                          }}
                          className="px-3 py-2 my-2 d-block w-100 form-control"
                          placeholder="Confirm Password"
                        />
                      </>
                    )}
                    <div
                      className="invalid-feedback"
                      style={
                        Termsvalidator || password == confirmPassword
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      Confirm Password Not Match with Password*
                    </div>

                    <div
                      className={
                        isActive ? "hide-animate-end" : "hide-animate "
                      }
                    >
                      <input
                        type="text"
                        style={
                          isActive && (Termsvalidator || address1.length > 2)
                            ? {}
                            : { borderColor: "red" }
                        }
                        value={address1}
                        onChange={(event) => {
                          SetElement("address1", event.target.value);
                        }}
                        className="px-3 py-2 my-2 d-block w-100 form-control"
                        placeholder="Address Line 1"
                      />
                      <input
                        type="text"
                        value={address2}
                        onChange={(event) => {
                          SetElement("address2", event.target.value);
                        }}
                        className="px-3 py-2 my-2 d-block w-100 form-control"
                        placeholder="Address Line 2 (Optional)"
                      />
                      <input
                        type="text"
                        value={town}
                        style={
                          isActive && (Termsvalidator || town.length > 2)
                            ? {}
                            : { borderColor: "red" }
                        }
                        onChange={(event) => {
                          SetElement("town", event.target.value);
                        }}
                        className="px-3 py-2 my-2 d-block w-100 form-control"
                        placeholder="town"
                      />
                      <input
                        type="text"
                        value={postcode}
                        style={
                          isActive && (Termsvalidator || postcode.length > 2)
                            ? {}
                            : { borderColor: "red" }
                        }
                        onChange={(event) => {
                          SetElement("postcode", event.target.value);
                        }}
                        className="px-3 py-2 my-2 d-block w-100 form-control"
                        placeholder="Postcode"
                      />

                      <p className="font-light pb-3 pt-2">
                        I confirm that I have, or will pay an amount of Income
                        and/or Capital Gains tax from 6 April to 5 April that is
                        at least equal to the amount of tax that all the
                        charities that I donate to will reclaim on my gifts for
                        that tax year.
                      </p>
                      <p className="font-light pb-3">
                        I understand that other taxes like Council Tax do not
                        qualify
                      </p>
                      <p className="font-light pb-3">
                        I understand parkrun will reclaim 25p of tax on every £1
                        that I have given <br />
                        <br />I understand that if I pay less Income Tax / or
                        Capital Gains tax in the current tax year than the
                        amount of Gift Aid claimed on all of my donations it is
                        my responsibility to pay any difference.
                      </p>

                      <div className="gift-aid" style={{ marginBottom: 25 }}>
                        <input
                          type="checkbox"
                          id="gift-aid-input"
                          onChange={(event) => {
                            SetElement("ConfirmGift", event.target.checked);
                          }}
                          className="position-absolute"
                          name="gift-aid"
                        />
                        <label
                          className="control control-checkbox position-relative"
                          style={{ right: -30, bottom: 7 }}
                        >
                          Confirm Gift Aid Declaration
                        </label>
                        <div
                          className="invalid-feedback"
                          style={
                            Termsvalidator || ConfirmGift
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          You must Confirm before submitting.
                        </div>
                      </div>
                    </div>

                    <div
                      className="p-3 my-3"
                      style={{ backgroundColor: "#d9d9d9" }}
                    >
                      <h1 className="font-semibold fs-5">Order Summary:</h1>
                      <p className="font-light fs-6 p-0 m-0">
                        Order type:<strong> {sub_period} </strong>
                      </p>
                      {sub_mode == "edit" && (
                        <>
                          <p className="font-light fs-6 p-0 m-0">
                            New Total:{" "}
                            <strong>
                              {" "}
                              £{sub_amount} (£ {sub_amount - sub_o_amount})
                            </strong>
                          </p>
                        </>
                      )}
                      {sub_mode != "edit" && (
                        <p className="font-light fs-6 p-0 m-0">
                          Total: <strong> £{sub_amount}</strong>
                        </p>
                      )}
                    </div>

                    <p className="font-semibold fs-5 pt-2  mb-0">
                      Updates and terms
                    </p>
                    <p className="font-light fs-6 pt-2 m-0">
                      parkrun forever would like to keep you in the loop, please
                      select the options below:
                    </p>

                    <div className="gift-aid" style={{ margin: "25px 0px" }}>
                      <input
                        onChange={(event) => {
                          setcb1(event.target.checked);
                        }}
                        type="checkbox"
                        id="gift-aid-input"
                        className="position-absolute"
                        name="gift-aid"
                      />
                      <label
                        className="control control-checkbox position-relative"
                        style={
                          Termsvalidator || cb1
                            ? { right: -30, bottom: 7 }
                            : { right: -30, bottom: 7, color: "red" }
                        }
                      >
                        Email for prize updates, charity stories and offers
                      </label>
                      <div
                        className="invalid-feedback"
                        style={
                          Termsvalidator || cb1
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        You must agree before submitting.
                      </div>
                    </div>
                    <div className="gift-aid" style={{ marginBottom: 25 }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setcb2(event.target.checked);
                        }}
                        id="gift-aid-input"
                        className="position-absolute"
                        name="gift-aid"
                      />
                      <label
                        className="control control-checkbox position-relative"
                        style={
                          Termsvalidator || cb2
                            ? { right: -30, bottom: 7 }
                            : { right: -30, bottom: 7, color: "red" }
                        }
                      >
                        Post for parkrun forever updates and rewards
                      </label>
                      <div
                        className="invalid-feedback"
                        style={
                          Termsvalidator || cb2
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        You must agree before submitting.
                      </div>
                    </div>
                    <div className="gift-aid" style={{ marginBottom: 25 }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setcb3(event.target.checked);
                        }}
                        id="gift-aid-input"
                        className="position-absolute"
                        name="gift-aid"
                      />
                      <label
                        className="control control-checkbox position-relative"
                        style={
                          Termsvalidator || cb3
                            ? { right: -30, bottom: 7 }
                            : { right: -30, bottom: 7, color: "red" }
                        }
                      >
                        I have read and agreed the{" "}
                        <Link className="text-muted" to={"/terms-conditions"}>
                          Terms & Conditions
                        </Link>
                      </label>
                      <div
                        className="invalid-feedback"
                        style={
                          Termsvalidator || cb3
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        You must agree before submitting.
                      </div>
                    </div>
                  </form>
                  <hr />
                  <div
                    className="invalid-feedback"
                    style={
                      Termsvalidator ||
                      (email?.length > 2 &&
                        Fname?.length > 2 &&
                        password === confirmPassword &&
                        (isActive
                          ? address1?.length > 2 &&
                            town?.length &&
                            postcode?.length &&
                            ConfirmGift
                          : true) &&
                        cb1 &&
                        cb2 &&
                        cb3 &&
                        email)
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    You must Fill all the Important Fields before submitting.
                  </div>
                  {!!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    email
                  ) &&
                  email?.length > 2 &&
                  Fname?.length > 2 &&
                  password === confirmPassword &&
                  (isActive
                    ? address1?.length > 2 &&
                      town?.length &&
                      postcode?.length &&
                      ConfirmGift
                    : true) &&
                  cb3 &&
                  email &&
                  ValidateEmail &&
                  ValidatePhone ? (
                    // <StripeFunction cb1={cb1} cb2={cb2} cb3={cb3} parkrunId={parkrunId} pw={password} fname={Fname} lname={Lname} cpw={confirmPassword} ad1={address1} ad2={address2} address={address1+' '+address2} town={town} postcode={postcode} email={email} name={Fname +' '+Lname} amount={parseInt(sub_period === 'Anually'?12*sub_amount:sub_amount)} period={sub_period === 'Anually' ? 'year':'month'} />
                    <Elements stripe={stripePromise}>
                      <StripeForm
                        editMode={sub_mode}
                        editSubID={sub_o_sub_item_id}
                        editTotal={sub_o_amount}
                        payType={sub_period === "Monthly" ? "Subscribe" : "Pay"} // pay = one off
                        show={true}
                        parkrunId={parkrunId}
                        password={password}
                        period={sub_period === "Monthly" ? "month" : "one_off"}
                        userName={Fname + " " + Lname}
                        amount={sub_amount}
                        email={email}
                        address1={address1 + " " + address2}
                        address2={town}
                        postcode={postcode}
                        userPhoneNumber={userPhoneNumber}
                        navigate={navigate}
                      />
                    </Elements>
                  ) : (
                    <>
                      <button
                        onClick={(e) => validateHandle()}
                        className="d-block w-100 text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-center text-decoration-none rounded-3"
                      >
                        {" "}
                        {sub_mode == "edit" ? "Update" : ""}{" "}
                        {sub_period == "Monthly" ? "Subscribe" : "Pay"} now
                      </button>
                      {sub_mode == "edit" && sub_o_amount < sub_amount && (
                        <div className="col-12 text-center">
                          <p className="error-msg">
                            Update subscribe will take effect
                            immediately(increase)
                          </p>
                        </div>
                      )}
                      {sub_mode == "edit" && sub_o_amount > sub_amount && (
                        <div className="col-12 text-center">
                          <p className="error-msg">
                            Update subscribe will take effect in the next
                            billing(decrease)
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Sitefooter />
    </>
  );
}
