import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { useEffect, useState } from "react";
import SiteHeader from "../components/SiteHeader";
import Sitefooter from "../components/footer";
import { ToastContainer, toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import apiClient from "../api.config";
import useDocumentTitle from "../components/useDocumentTitle";

export default function ResetPassword() {
  useDocumentTitle("Reset Password");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  let userDetail = secureLocalStorage.getItem("LogObject");

  async function loginHandler(e) {
    e.preventDefault();
    if (email.trim().length === 0) {
      toast.error(`Please Enter Your Email`);
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      toast.error(`Invalid Email`);
    } else {
      try {
        const response = await toast.promise(
          apiClient.post(
            `${process.env.REACT_APP_API_URL}/api/v1/auth/request`,
            {
              userEmail: email,
              siteID: "146a712f-5df6-4056-a65b-e7dce24f7aa0",
            },
            {
              withCredentials: true,
              credentials: "same-origin",
            }
          ),
          {
            pending: "Please wait...",
            success:
              "Thank you! If we have record of a user with your email address, you will receive an email with a link to reset your password.",
          }
        );
      } catch (error) {
        console.log(error);

        if (error?.response?.status === 403) {
          toast.error(`${error?.response?.data}`);
        } else {
          toast.error(`Something Went Wrong`);
        }
      }
    }
  }
  return (
    <>
      <SiteHeader />
      <ToastContainer />
      <section
        className=" bg-branding-2 font-face-sh"
        style={{ marginTop: "-110px", padding: "90px 0 20px" }}
      >
        <div className="container">
          <div className="row py-3">
            <div className="col-12 text-center">
              <div className="col-md-9 col-lg-5 col-sm-12 bg-light mx-auto rounded-3 p-md-5 p-3">
                <div className="col-12">
                  <h1
                    className="font-semibold fs-3"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    Welcome back
                  </h1>
                </div>
                <div className="col-12">
                  <form onSubmit={loginHandler} noValidate>
                    <div className="form-group field-wrap position-relative">
                      <div className="form-control-wrap  form-control-email-wrap">
                        <input
                          type="email"
                          className="p-3 my-3 form-control"
                          name="email"
                          id="username"
                          placeholder="E-mail"
                          data-invalid-message="Invalid email"
                          pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        <div className="invalid-feedback mt-2 ml-2" />
                      </div>
                    </div>

                    <input
                      type="hidden"
                      name="_csrf_token"
                      defaultValue="jcgQUNcBgCYL50vIjR5TE7sRNFlL6Z7Q5XQAUn_x3gY"
                    />
                    <input
                      type="hidden"
                      name="_target_path"
                      defaultValue="my_donation_entries"
                    />
                    <div className="form-group field-wrap">
                      <button
                        type="submit"
                        className="site-button-primary rounded-3 border-0 w-100 fs-4 p-2 text-light font-regular bg-branding-3"
                        name="submit"
                      >
                        Reset password
                      </button>
                    </div>
                  </form>
                  <hr />
                  <div className="d-flex flex-row justify-content-between">
                    <div className="p-2 text-muted">
                      <span>Don't have an account?</span>
                    </div>
                    <Link
                      to={"/donation-plan"}
                      className="btn site-button-secondary"
                    >
                      <div
                        className="p-2 rounded-3 px-md-5 px-2"
                        style={{ border: "1px solid #000", minWidth: 130 }}
                      >
                        Sign Up
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Sitefooter />
    </>
  );
}
