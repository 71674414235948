import { useQuery } from "@tanstack/react-query";
import { fetchUserData } from "../../../api/user";
import { selectUser } from "../../../store/user/selectors";

export const useUserData = (select) => {
  const user = selectUser();

  return useQuery({
    queryKey: ["FETCH_USER_DATA", user?.userID],
    queryFn: () => fetchUserData(user?.userID),
    retry: 0,
    select,
    refetchInterval: 30000, // refetch every 30 seconds
  });
};