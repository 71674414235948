import { webSvc } from ".";
const APIURL = process.env.REACT_APP_API_URL + "/api/v1";
// Login function
export const login = async (payload) => {
  const response = await webSvc.post(APIURL + "/auth/local", payload);
  return response.data;
};

// Fetch user data function
export const fetchUserData = async (userID) => {
  const response = await webSvc.get(APIURL + `/users/${userID}/data`);
  return response.data;
};

// Create user function
export const createUser = async (payload) => {
  const response = await webSvc.post(APIURL + "/users", payload);
  return response.data;
};

// Fetch users function
export const fetchUsers = async (payload) => {
  const response = await webSvc.get(APIURL + "/users", {
    params: payload,
  });
  return response.data;
};

// Update user function
export const updateUser = async (payload) => {
  const response = await webSvc.put(APIURL + "/users", payload);
  return response.data;
};