import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../App.css'
import { useEffect, useState } from 'react'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { setUser } from "../store/user/actions";

export default function Login() {
    useDocumentTitle('Login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    let userDetail = secureLocalStorage.getItem('LogObject');
    const siteId = process.env.REACT_APP_SITE_ID;

    useEffect(() => {
        if(userDetail){
            navigate('/account/my-donation-entries');
        }
        document.body.classList.add('bg-branding-2');
        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [])

    async function loginHandler(e) {
        e.preventDefault();
        if (email.trim().length === 0 && password.trim().length === 0) {
            toast.error(`Please Enter Your Email and Password`);
        }
        else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            toast.error(`Invalid Email`);
        } else if (password.trim().length === 0) {
            toast.error(`Please Enter Your Password`);
        } else {

            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/auth/local`,
                    {
                        userEmail: email,
                        userPassword: password
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );

                console.log('toast promise response', response.data);

                if (response.data.status === true && response.data.error === null) {
                    toast("Successfully Authenticated", { type: "success" });
                    secureLocalStorage.setItem("LogObject", { userID: response.data.content.userID, email: email, loggedin: true })
                    setUser({
                        userID: response.data.content.userID,
                        accessToken: response.data.content.accessToken,
                        userRole: response.data.content.userRoleID,
                      });
                    navigate('/account/my-donation-entries');
                    const userID = response.data.content.userID;

                    // For sign-in
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'user_signed_in',
                        'user_id': userID,
                        'user_email': email
                    });
                } else {
                    toast("Something went wrong While authenticating", { type: "error" });
                }


            } catch (error) {

                if (error.response.status === 403) {
                    toast.error(`${error.response.data.content.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }

        }





    }
    return (
        <>{!userDetail ?
            <>
                <SiteHeader />
                <ToastContainer />
                <section className=" bg-branding-2 font-face-sh" style={{ marginTop: '-110px', padding: '90px 0 20px' }}>
                    <div className='container'>
                        <div className='row py-3'>
                            <div className='col-12 text-center'>
                                <div className='col-md-10 col-lg-6 col-xl-5 col-sm-12 bg-light mx-auto rounded-3 p-md-5 p-4 p-3'>
                                    <div className='col-12'>
                                        <h1 className='font-semibold fs-3 pb-2' style={{ borderBottom: '1px solid #00000020' }}>Welcome back</h1>
                                    </div>
                                    <div className='col-12'>
                                        <p className='font-light fs-6 text-muted' >Enter your email and password</p>
                                    </div>
                                    <div className='col-12'>
                                        <form onSubmit={loginHandler} noValidate>
                                            <div className="form-group field-wrap position-relative">
                                                <div className="form-control-wrap  form-control-email-wrap">
                                                    <input type="email" className="p-3 my-3 form-control" name="_username" id="username" placeholder="E-mail" data-invalid-message="Invalid email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" required="required" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <div className="invalid-feedback mt-2 ml-2" />
                                                </div>
                                            </div>
                                            <div className="form-group field-wrap position-relative">
                                                <div className="form-control-wrap  form-control-password-wrap">
                                                    <input type="password" className="p-3 my-3 form-control" name="_password" id="password" placeholder="Password" data-invalid-message="Must contain at least 6 characters" required="required" minLength={6} value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <div className="invalid-feedback mt-2 ml-2" />
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between">
                                                <div className="p-2">
                                                    <label className="form-check-label">
                                                    </label>
                                                </div>
                                                <div className="p-2">
                                                    <span id="fileHelp">
                                                        <a className='text-muted text-decoration-none' href="/password-reset/">Forgot your Password?</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <input type="hidden" name="_csrf_token" defaultValue="jcgQUNcBgCYL50vIjR5TE7sRNFlL6Z7Q5XQAUn_x3gY" />
                                            <input type="hidden" name="_target_path" defaultValue="my_donation_entries" />
                                            <div className="form-group field-wrap">
                                                <button type="submit" className="site-button-primary rounded-3 border-0 w-100 fs-3 p-2 text-light font-regular bg-branding-3" name="submit">Log In</button>
                                            </div>
                                        </form>
                                        <hr />
                                        <div className="d-flex flex-row justify-content-between align-items-center mb-md-0 mb-4">
                                            <div className="p-2 text-muted">
                                                <span>Don't have an account?</span>
                                            </div>
                                            <Link to={"/donation-plan"} className="btn site-button-secondary">
                                                <div className="p-2 rounded-3 px-md-5 px-2" style={{ border: '1px solid #000', minWidth: 130 }}>
                                                    Sign Up
                                                </div>
                                            </Link>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               <Sitefooter /></> 
               :
                navigate('/account/my-donation-entries')}

        </>
    )
}

