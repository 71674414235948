import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AccountTopLogo from '../images/account/account-top-logo.svg'
import RunningIcon from '../images/account/running-icon.svg'
import FemaleRunnersIcon from '../images/account/female-runners-icon.svg'
import JuniorsIcon from '../images/account/juniors-icon.svg'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage'

export default function RecdonationAccountBanner(props) {
    const navigate = useNavigate();
    function logout(e){
        secureLocalStorage.clear();
       e.preventDefault()
          console.log("Hello")
            axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`).then(res=>{

            secureLocalStorage.removeItem("LogObject");
            navigate('/')

            })  

      }
  return (
    <>
        <div className="recdonation-account-banner">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-5 text-center">
                        <img src={AccountTopLogo} />
                        <h1 className="mt-3 mb-2 text-light fs-2">{props.name}</h1>
                        <p>
                            <Link className='text-light fw-normal' onClick={(e)=>logout(e)} to={'/'}>Log Out</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
