import axios from "axios";
import { queryClient } from "../tanstack-query";
import { QueryKeys } from "../constants/query-keys";

export const WEB_SVC_URL = process.env.REACT_APP_API_URL;
export const SITE_ID = process.env.REACT_APP_SITE_ID;

const WEB_SVC_DEFAULTS = {
  headers: {
    SITE_ID,
    "Content-Type": "application/json",
    withCredentials: true,
  },
};

export const createAxiosInstance = (baseURL, defaults) => {
  const instance = axios.create({
    baseURL,
    ...defaults,
  });

  instance.interceptors.request.use(
    (config) => {
      const accessToken = queryClient.getQueryData([QueryKeys.SESSION_TOKEN]);
      console.log("🚀 ~ createAxiosInstance ~ accessToken:", accessToken)
      if (accessToken?.content.accessToken) {
        config.headers.Authorization = `Bearer ${accessToken.content.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export const webSvc = createAxiosInstance(WEB_SVC_URL, WEB_SVC_DEFAULTS);