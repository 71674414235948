import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../App.css'
import { useEffect, useState } from 'react'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';


export default function UserResetPassword() {
    useDocumentTitle('User Reset Password');
    
    const Urlparams = useLocation().search;
    const [password, setPassword] = useState('');
    const [Confirmpassword, setConfirmpassword] = useState('');
    const Params_userID = new URLSearchParams(Urlparams).get("userID");
    const Params_resetToken = new URLSearchParams(Urlparams).get("resetToken");
    const navigate = useNavigate();
  
    // console.log(Params_userID,Params_resetToken);
    function timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  
  
    async function ValidateUser() {
      if (Params_userID === null || Params_resetToken === null) {
        toast.error('Invalid Reset token');
      } else if (Confirmpassword.trim().length === 0 && password.trim().length === 0) {
        toast.error('Please Enter Password and Confirm Password')
      } else if (password.trim().length === 0) {
        toast.error('Please Enter Password')
      } else if (Confirmpassword.trim().length === 0) {
        toast.error('Please Enter Confirm Password')
      } else if (password != Confirmpassword) {
        toast.error('Invalid Confirm Password')
      } else {
        try {
  
          const response = await toast.promise(apiClient.post(
            `${process.env.REACT_APP_API_URL}/api/v1/auth/reset`,
            {
              "userID": Params_userID,
              "userPasswordToken": Params_resetToken,
              "userPassword": Confirmpassword
            },
            {
              withCredentials: true,
              credentials: "same-origin"
            }
          ), {
            pending: 'Please wait...',
          }
          );
  
          if (response.data.content === 'Token Matches. Password Reset Succesfully') {
            toast("Passwort Reset Successfully", { type: "success" });
            await timeout(2000);
            navigate('/login');
          } else {
            toast("Passwort Reset Fail", { type: "error" });
          }
        } catch (error) {
          console.log(error);
          toast.error(`${error.message} Node Not found`);
        }
      }
  
    }

    return (
        <>
            <SiteHeader />
            <ToastContainer />
            <section className=" bg-branding-2 font-face-sh" style={{ marginTop: '-110px', padding: '90px 0 20px' }}>
                <div className='container'>
                    <div className='row py-3'>
                        <div className='col-12 text-center'>
                            <div className='col-md-9 col-lg-5 col-sm-12 bg-light mx-auto rounded-3 p-md-5 p-3'>
                                <div className='col-12'>
                                    <h1 className='font-semibold fs-3' style={{ borderBottom: '1px solid #00000020' }}>Welcome back</h1>
                                </div>
                                <div className='col-12'>
                                    <form>
                                        <div className="form-group field-wrap position-relative">
                                            <div className="form-control-wrap  form-control-email-wrap">
                                                {/* <input type="email" className="p-3 my-3 form-control" name="email" id="username" placeholder="E-mail" data-invalid-message="Invalid email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" required="required" value={email} onChange={(e) => setEmail(e.target.value)} /> */}
                                                <div className="invalid-feedback mt-2 ml-2" />
                                                <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="New Password" className="p-3 my-3 form-control" />
                                                <input type="password" onChange={(event) => setConfirmpassword(event.target.value)} placeholder="Confirm New Password" className="p-3 my-3 form-control" />

                                            </div>
                                        </div>

                                        <input type="hidden" name="_csrf_token" defaultValue="jcgQUNcBgCYL50vIjR5TE7sRNFlL6Z7Q5XQAUn_x3gY" />
                                        <input type="hidden" name="_target_path" defaultValue="my_donation_entries" />
                                        <div className="form-group field-wrap">
                                            {/* <button type="submit" className="site-button-primary rounded-3 border-0 w-100 fs-4 p-2 text-light font-regular bg-branding-3" name="submit">Reset password</button> */}
                                            <button onClick={ValidateUser} className="site-button-primary rounded-3 border-0 w-100 fs-4 p-2 text-light font-regular bg-branding-3" type="button" name="button">Save</button>
                                        </div>
                                    </form>
                                    <hr />
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2 text-muted">
                                            <span>Don't have an account?</span>
                                        </div>
                                        <Link to={"/donation-plan"} className="btn site-button-secondary">
                                            <div className="p-2 rounded-3 px-5" style={{ border: '1px solid #000' }}>
                                                Sign Up
                                            </div>
                                        </Link>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Sitefooter /></>


    )
}

