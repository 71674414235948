import React, { useEffect } from "react";
import LogoWhite from "../images/logo-white.svg";
import plane from "../images/prize/plane-new.svg";
import Trophy from "../images/trophy.svg";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Prizeslider from "../components/prize-slider";
import "../App.css";
import MyAccordion from "../components/Accordian";
import Testimonials from "../components/testimonials";
import BottomSlider from "../components/bottom-slider";
import SiteHeader from "../components/SiteHeader";
import Sitefooter from "../components/footer";
import useDocumentTitle from "../components/useDocumentTitle";

export default function Home() {
  useDocumentTitle("Home");
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_content: urlParams.get("utm_content"),
    };
    secureLocalStorage.setItem("utmParams", JSON.stringify(utmParams));
    console.log("utmParams", utmParams);
  }, []);

  return (
    <>
      <SiteHeader />
      <section
        className="hero-section herosec-img font-face-sh"
        style={{
          height: "100vh ",
          marginTop: "-110px",
          padding: "170px 0 200px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <img src={LogoWhite} alt="Logo" width={"150px"} />
            </div>
            <div className="col-12 text-center my-3">
              <h1 className="text-light display-4 font-bold ">
                Support parkrun: Donate Today and Win Big with Our Prize Draws
              </h1>
            </div>
            <div className="col-12 text-center my-3 ">
              <div className="w-70 mx-auto wt-100">
                <h2 className="text-light fs-5 font-regular ">
                  Why parkrun Needs Your Support
                </h2>
                {/* <em> */}
                <p className="text-light fs-6 font-regular">
                  parkrun is committed to providing free, weekly, community
                  events that promote health and wellbeing. However, delivering
                  these events incurs costs, including equipment, training, and
                  support services. Each parkrun event costs approximately £83
                  to deliver.
                </p>

                <p className="text-light fs-6 font-regular">
                  Support parkrun By donating to parkrun, you help ensure these
                  events remain free and accessible to everyone, fostering
                  community spirit and encouraging a healthier lifestyle.
                </p>
                {/* </em> */}
              </div>
            </div>
            <div className="col-12 text-center my-3 ">
              <div className="mx-auto">
                <Link
                  to={"/Support"}
                  className="text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill"
                >
                  Donate to Support parkrun
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="stories-section-title font-face-sh mt-5 bg-branding-2">
        <div>
          <p
            className="transation-ease-in-out yellow-strip text-center w-100 m-0"
            style={
              scroll
                ? { top: "-50px", position: "relative", left: 0, bottom: 0 }
                : { top: "auto", position: "fixed", left: 0, bottom: 0 }
            }
          >
            <img className="hide_responsive" src={Trophy} />
            <span className="text-light fs-5 mx-md-5">
              As a thank you, we will enter you into our monthly parkrun prize
              draw!
            </span>
            <img className="hide_responsive" src={Trophy} />
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-light fs-1 text-center mt-md-5 font-bold ">
                Join Our Prize Draws and Make a Difference{" "}
              </h2>
              <div className="mx-auto w-70 wt-100">
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  When you support parkrun Forever with a regular donation,
                  you're automatically entered into our monthly{" "}
                  <b>Thank You Prize Draw.</b>
                </p>
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  This is our way of expressing gratitude for your generosity.
                  Prizes range from exclusive parkrun merchandise to unique
                  experiences. Your participation not only gives you a chance to
                  win but also directly contributes to keeping parkrun events
                  free and thriving.
                </p>
              </div>
              <div className="col-12 text-center my-3 ">
                <div className="mx-auto my-4">
                  <Link
                    to={"/Support"}
                    className="text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill"
                  >
                    Enter Prize Draws
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center home-video">
            <iframe
              className="text-center my-5 mx-auto col-md-9 col-sm-12"
              height="540px"
              src="https://www.youtube.com/embed/iJT_t_bQE0w"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-12">
              <h2 className="text-light fs-1 text-center mt-md-5 font-bold ">
                How Your Donations Help parkrun{" "}
              </h2>
              <div className="mx-auto w-70 wt-100">
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  Your contributions have a tangible impact:
                </p>

                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  <b>Establishing New Events:</b> Donations have facilitated the
                  creation of over 50 parkruns in areas of deprivation, bringing
                  communities together and promoting inclusivity.
                </p>
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  <b>Supporting Volunteers:</b> Funds help train and equip the
                  incredible volunteers who make parkrun possible every week.
                </p>
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  <b>Enhancing Technology:</b> Investing in cutting-edge
                  technology ensures efficient event management and a seamless
                  experience for all participants.
                </p>
                <p className="text-light fs-6 text-center my-md-2 font-regular">
                  By donating, you play a crucial role in sustaining and
                  expanding parkrun's mission to create a healthier, happier
                  planet.
                </p>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="w-100 mx-auto text-center">
                <Link
                  to={"/Support"}
                  className=" text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill"
                >
                  Support Free parkrun Events
                </Link>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="mx-lg-auto text-center">
              {/* <div className='mx-auto text-center col-md-6 col-sm-12 bg-branding-1 rounded-4 position-relative' style={{top: '50%'}}>
                  <p className='text-white pt-5 font-bold display-1'>6,010,812</p>
                  <p className='text-white pb-5 rounded-4 font-regular'>People have joined parkrun since 2004</p>                  
              </div> */}
            </div>
          </div>
          <div className="position-relative" style={{ top: 100 }}>
            <hr
              className="branding-1 text-center mx-auto"
              style={{ opacity: 1, border: "2px solid", width: "20%" }}
            />
          </div>
        </div>
      </section>
      <section
        className="prizes-section font-face-sh mb-5 pb-5"
        style={{ marginTop: "130px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto text-center">
              <h2 className="black-clr display-4 font-semibold">
                Start Supporting parkrun Forever Today!{" "}
              </h2>
              <p className="black-clr  font-semibold">
                Ready to make a difference? Here's how you can get involved:{" "}
              </p>
              <p className="black-color">
                <b>Donate Now:</b> Your generosity helps keep parkrun free for
                everyone, forever.
              </p>

              <p className="black-color">
                <b>Join the Prize Draw:</b> Regular donors are automatically
                entered into our monthly Thank You Prize Draw, with exciting
                prizes to be won.
              </p>
              <p className="black-color">
                <b>Spread the Word:</b> Encourage friends and family to support
                parkrun Forever and help us reach more communities.
              </p>
              <p className="black-clr">
                Together, we can ensure parkrun continues to thrive and make a
                positive impact worldwide.
              </p>
              {/* <p className='black-clr fs-4 mt-5 mb-4 font-semibold'>This month prizes:</p> */}
            </div>
          </div>
          <div className="row">
            {/* <div className='col-md-12 col-sm-12'>
              <div className='row'>
                <div className='col-md-4 col-sm-12 m-0 py-2 bg-branding-4' style={{borderRadius: '25px 0px 0px 25px'}}>
                <img  className='w-100 h-auto px-5' src={plane} alt="Prize Image" />
                <div>
                  <p className='text-light display-5 px-5 font-semibold'>UK weekend break for two!</p>  
                  <p className='text-light fs-5 px-5 font-regular'>A parkrun weekend break for two, anywhere in the UK. Grand prize winner announced in January.</p>  

                  </div>
                </div>
                <div className='col-md-8 col-sm-12 m-0 p-0'>
                  <img className='w-100 h-100' src={require('../images/prize/1.jpeg')} alt="Prize Image" style={{borderRadius: '0 25px 25px 0'}} />
                </div>
              </div>
            </div> */}
            {/* <div className=' mx-0 px-0 mb-3'>
            <Prizeslider/>
            </div> */}
          </div>

          {/* <div className=' row my-5'>
          <MyAccordion/>
          </div> */}
          <div className="w-100 mx-auto mt-4 text-center">
            <Link
              to={"/Support"}
              className="text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill"
            >
              Enter parkrun Prize Draw{" "}
            </Link>
          </div>
          <div className="row my-3 mx-auto w-75 wt-95 text-center">
            <Testimonials />
            <p className="col-md-6 col-sm-12 mx-auto mt-0 mb-4 fs-2 font-semibold text-center">
              Help keep parkrun free, for everyone, forever.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-5 py-5 bg-branding-2 ">
        <div className="container mx-auto mb-5">
          <img
            width={"100%"}
            src={require("../images/people-joined.jpeg")}
            alt=""
          />

          <div
            className="col-md-6 col-sm-12 mx-auto position-relative bottom-slider"
            style={{ zIndex: 9, marginTop: -140 }}
          >
            <BottomSlider />
          </div>
        </div>
      </section>
      <Sitefooter />
    </>
  );
}
