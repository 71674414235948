import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 86400000, // 1 Day in milliseconds
    },
  },
});

export const createSessionPersister = (key, storage) =>
  createSyncStoragePersister({
    key,
    storage,
  });