import { useEffect } from "react";

function useDocumentTitle(title) {
  useEffect(() => {
    // document.title = `Support parkrun | Donate & Win with parkrun Forever | Join Our Prize Draw`;
    document.title = title + ' - Parkrun Forever Draw | Thank You Prize Draw';
  }, [title]);
}

export default useDocumentTitle;
